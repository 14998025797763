import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "@aparraga/ahoracrypto-client-common/src/app/common/navigation/navigation.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FlowService {

  public steps;
  public onFlowFinished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onFlowStarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public constructor(protected router: Router,
                     protected navigationService: NavigationService) {

  }

  public getCurrentStep() {
    let currentUrl = this.navigationService.getCurrentUrl();
    //remove query params
    if(currentUrl != null) {
      currentUrl = currentUrl.split('?')[0];
      return this.steps.find(step => step.url == currentUrl);
    }
    return null;
  }

  public getStep(stepId) {
    return this.steps.find(step => step.id == stepId);
  }

  public executeControl(step = null) {
    if(step == null) {
      step = this.getCurrentStep();
    }
    return new Promise((resolve, reject) => {
      if (step != null && typeof step['flowControl'] === 'function') {
        (step['flowControl'] as (s: any) => Promise<any>)(step).then(stepIdToGoTo => {
          if (stepIdToGoTo != null) {
            resolve(true);
            this.goToStep(stepIdToGoTo);
          }
          else {
            resolve(false);
          }
        });
      }
      else {
        resolve(false);
      }
    });
  }

  public transitionTo(transitionName) {
    let currentStep = this.getCurrentStep();
    if(currentStep != null && typeof currentStep.transitions[transitionName] != 'undefined') {
      let nextStepId = currentStep.transitions[transitionName];
      let nextStep = this.getStep(nextStepId);
      this.executeControl(nextStep).then(transitionedToAnotherStep => {
        if(!transitionedToAnotherStep) {
          this.goToStep(nextStep);
        }
      });
    }
  }

  public finishFlow() {
    this.onFlowFinished$.next(true);
  }

  public goToStep(nextStep) {
    if(typeof nextStep == 'string') {
      nextStep = this.getStep(nextStep);
    }
    if(nextStep != null) {
      let url = nextStep.url;
      this.router.navigate([url]);
    }
    else {
      // todo error
    }
  }

}
